import { antiflicker } from './antiflicker';

class Switcher {
  constructor(el, { event, target, context, onSwitch }) {
    this.$el = $(el);
    this.event = event || "click";
    this.context = context || el.dataset.switcherContext || null;
    this.target = target || `[data-switcher-container="${el.dataset.switcherTarget}"]`;
    this.onSwitchCallback = onSwitch

    $.data(el, "pluginSwitcherContext", this.context);
    if (!this.$el.is(this.activeSwitcher())) this.targetContent().hide();

    this.$el.on(this.event, $.proxy(this.onSwitch, this));
  }

  destroy() {
    this.$el.off(this.event, this.onSwitch);
  }

  activeSwitcher() {
    return $(`[data-switcher-active="true"]`).filter((idx, el) => {
      return $.data(el, "pluginSwitcherContext") === this.context;
    })
  }

  activeContent() {
    const container = this.activeSwitcher().data("switcherTarget");
    return $(`[data-switcher-container="${container}"]`);
  }

  targetContent() {
    return $(this.target);
  }

  onSwitch() {
    if (this.$el.attr("data-switcher-active") == "true") return;

    if (this.onSwitchCallback) {
      this.onSwitchCallback(this);
    } else {
      this.activeContent().hide();
      this.targetContent().show();
    }

    this.activeSwitcher().attr('data-aria-expanded', 'false');
    this.activeSwitcher().attr('data-switcher-active', 'false');
    this.$el.attr('data-switcher-active', 'true');
    this.$el.attr('data-aria-expanded', 'true');
  }
}

jQuery.fn.extend({
  switcher: function (options) {
    return this.each(function() {
      if ($.data(this, "pluginSwitcher")) {
        let instance = $.data(this, "pluginSwitcher");
        instance.destroy();
      }

      $.data(this, "pluginSwitcher", new Switcher(this, options || {}));
    });
  }
})

antiflicker((resolve, reject) => {
  $("[data-switcher-target]").switcher();
  const href = $(location).attr('hash');
  const switcher = $(`[data-switcher-target][href="${href}"]`).data("pluginSwitcher");

  if (switcher) {
    $.proxy(switcher.onSwitch, switcher)();
  }

  resolve(true);
});
