$(document).on('click', '.js-copy-to-clipboard', function(e) {
  e.preventDefault();
  let $link = $(this).find($(this).attr('data-clip-input'));

  // Use the new Clipboard API if available
  if (navigator.clipboard) {
    navigator.clipboard.writeText($link.val());
    return;
  }

  // Fallback to the old document.execCommand() method which is deprecated but still widely supporte
  $link.toggleClass('hidden');
  $link.select();
  document.execCommand('copy');
  $link.toggleClass('hidden');
});
