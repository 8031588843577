LemonFrog.NavigationSearch = (function () {
  const moduleName = 'NavigationSearch',
    searchForm = '.nav-search__form',
    searchFormBackground = '.nav-search__form-background';

  function init() {
    handleNavigationSearchForm();
  }

  function handleNavigationSearchForm() {
    $(document).on('click', '.js-nav-search-trigger, .js-nav-search-mobile-trigger', showNavigationSearchForm);
    $(document).on('click', '.js-navigation', function (e) {
      const clickedEl = $(e.target);
      if ($(searchForm).is(":visible") &&
        !clickedEl.closest('.js-nav-search-mobile-trigger').length &&
        (!clickedEl.closest('.nav-search').length || clickedEl.is('.nav-search__form-background'))) {
        hideNavigationSearchForm();
      }
    });
  }

  function showNavigationSearchForm() {
    $(searchFormBackground).fadeIn();
    $(searchForm).show(0, () => $(searchForm).addClass("mobile-open"));
    $('body').css({overflow: 'hidden', paddingRight: window.innerWidth - document.body.clientWidth + 'px'});
  }

  function hideNavigationSearchForm() {
    $(searchFormBackground).fadeOut();
    $(searchForm).removeClass("mobile-open").fadeOut();
    $('body').css({overflow: '', paddingRight: ''});
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.NavigationSearch);
