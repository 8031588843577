export function initModule(moduleConst, options = {}) {
  const antiflicker = options['antiflicker'] || false,
        onTurboLoad = options['onTurboLoad'] || false,
        perElement = options['perElement'] || false;

  const onDomReady = () => {
    let $element = $(`[data-module="${moduleConst.moduleName}"]`);
    if ($element.length) {
      perElement ? $element.each((i, el) => moduleConst.init($(el))) : moduleConst.init()
    }
  };

  const onAntiflick = (resolve, reject) => {
    onDomReady();
    resolve(true);
  }

  antiflicker ? window.antiflicker(onAntiflick) : $(onDomReady);

  if (onTurboLoad) {
    document.addEventListener("turbo:load", onDomReady);
  }
}

window.LemonFrog.initModule = initModule;
