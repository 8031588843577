import { requireAll } from '/helpers/require';

let $ = require("jquery");
window.jQuery = $;
window.$ = $;

// polyfills
import 'core-js';
import 'core-js/proposals/regexp-escaping';
import '@webcomponents/custom-elements'; // safari 9
require('intersection-observer'); // safari 9

// required libs
require('jquery-ujs');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-de');
require('jquery-ui/ui/i18n/datepicker-es');
require('jquery-ui/ui/i18n/datepicker-fr');
require('jquery-ui/ui/i18n/datepicker-it');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/slider');
require('jquery.guillotine');
require('jquery-touchswipe');
require('parsleyjs');
require('mailcheck');
require('remotipart');
require('readmore-js');
require('cookieconsent');
require('sortablejs');

window.LemonFrog = {};

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

requireAll(require.context('./required/', true));
requireAll(require.context('./cable/channels/'), true);
requireAll(require.context('./jquery/common/', true));
requireAll(require.context('../components/', true,  /\.js$/));
