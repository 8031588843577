LemonFrog.AskAiComponent = (function () {
  const moduleName = 'AskAiComponent';
  const moduleRef = `[data-module="${moduleName}"]`;
  const readyContentRef = '.js-state-ready-content';
  const loadingContentRef = '.js-state-loading-content';
  const errorContentRef = '.js-state-error-content';

  function init($el) {
    const context = { $el: $el, state: "ready" }

    $el.on("click", handleClick.bind(context))
  }

  function handleClick(e) {
    e.preventDefault();
    if (!["ready", "error"].includes(this.state)) return;

    const url = e.currentTarget.href;
    const fieldId = e.currentTarget.dataset.fieldId;
    const userId = e.currentTarget.dataset.userId;

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        field_id: fieldId,
        user_id: userId
      })
    }

    performRequest.call(this, url, options);
  }

  async function performRequest(url, options) {
    let stateCb = handleStateChange.bind(this);

    stateCb("loading")
    const response = await fetch(url, options);

    if (response.ok) {
      stateCb()
      return;
    }

    stateCb("error")
    return;
  }

  function handleStateChange(newState) {
    this.state = newState;
    resetState.call(this.$el);
    switch (this.state) {
      case "loading":
        this.$el.find(loadingContentRef).show();
        break;
      case "error":
        this.$el.find(errorContentRef).show();
        break;
      case "ready":
        this.$el.find(readyContentRef).show();
        break;
      default:
        break;
    }
  }

  function resetState() {
    this.find(readyContentRef).hide();
    this.find(loadingContentRef).hide();
    this.find(errorContentRef).hide();
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.AskAiComponent, { perElement: true });
