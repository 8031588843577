import channel from '/cable/channels/typing_indicators';

LemonFrog.TypingIndicator = (function () {
  const moduleName = "TypingIndicator";

  const typingTriggerSelector = '.js-typing-indicator-trigger';
  let displayingTimers = {};

  const SEND_DELAY = 2000;
  const DISPLAYING_TIME = 4000;

  function init() {
    $(typingTriggerSelector).each(function() {
      let isAbleToSend = true;
      let sendTimeout;
      let conversationId = $(this).data('conversation-id');

      $(this).keydown(function() {
        if (!isAbleToSend) return;

        channel.sendMessage(conversationId);

        isAbleToSend = false;
        clearTimeout(sendTimeout);
        setTimeout(function() {
          isAbleToSend = true;
        }, SEND_DELAY);
      });
    })
  }

  function mainBoxSelector(conversationId) {
    return `.typing-indicator[data-conversation-id="${conversationId}"]`;
  }

  function messageSnippetSelector(conversationId) {
    return `.conversations-interface-users-list__message-snippet[data-conversation-id="${conversationId}"]`;
  }

  function showIndicator(conversationId) {
    $(mainBoxSelector(conversationId)).removeClass('u-invisible');
    $(messageSnippetSelector(conversationId)).find('.typing-indicator').siblings().addClass('u-invisible');
  }

  function hideIndicator(conversationId) {
    $(mainBoxSelector(conversationId)).addClass('u-invisible');
    $(messageSnippetSelector(conversationId)).find('.typing-indicator').siblings().removeClass('u-invisible');
  }

  function startIndicatorTimer(conversationId) {
    showIndicator(conversationId);

    clearTimeout(displayingTimers[conversationId]);
    displayingTimers[conversationId] = setTimeout(function() {
      hideIndicator(conversationId);
    }, DISPLAYING_TIME);
  }

  return {
    init: init,
    moduleName: moduleName,
    showIndicator: showIndicator,
    hideIndicator: hideIndicator,
    startIndicatorTimer: startIndicatorTimer
  };
}());

LemonFrog.initModule(LemonFrog.TypingIndicator);
