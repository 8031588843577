import { getCookie } from '/helpers/cookies';

$(function () {
  $('.footer-logos a').on('mousedown', function () {
    let locale = getCookie('locale');

    if (locale !== undefined) {
      let link = $(this);
      if (!link[0].dataset.partner) {
        let splitLink = link[0].href.split('/');

        splitLink[3] = locale;
        link[0].href = splitLink.join('/');
      }
    }
  });
});

