const SOURCE = '/api/v1/autocomplete';

$(document).ready(function () {
  let placesAutocomplete = function (id, source, select) {
    let $input = $(id);
    if ($input != null) {
      $input.autocomplete({
        source: source,
        select: select,
        open: open,
        appendTo: ".fixed-bottom-block",
        create: () => { $input.removeAttr("autocomplete") }
      });
    }
  };

  function open (event, ui) {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
    }

    let acData = $(this).data('ui-autocomplete');
    acData
      .menu
      .element
      .find('div')
      .each(function () {
        let me = $(this);
        let keywords = acData.term.split(' ').join('|');
        me.html(me.text().replace(
          new RegExp('(' + '^' + RegExp.escape(keywords) + ')', 'gi'), '<b>$1</b>')
        );
      });
  }

  function sendForm (event, ui) {
    $(this).val(ui.item.value);
    $(this).parents('form').submit();
  }

  function extractCity (event, ui) {
    event.preventDefault();
    let locationData = ui.item.value;
    let locationDataArray = locationData.split(/\s(.+)/);
    let zip = locationDataArray[0];
    let city = locationDataArray[1];
    let $postalCode = $('.js-postal-code');
    let $locality = $('.js-locality');
    $postalCode.val(zip);
    $locality.val(city).trigger('change');

    LemonFrog.UsersCountInArea.getUsersCountInArea(locationData);
    $postalCode.parsley().validate();
  }

  function zips (request, response) {
    let onlyNumbers = request.term.replace(/[^0-9]/g, '');
    if (onlyNumbers === '') {
      response([]);
    } else {
      jQuery.get(SOURCE, {
        term: onlyNumbers
      }, function (data) {
        response(data);
      });
    }
  }

  placesAutocomplete('input[data-autocomplete="place"]', SOURCE, sendForm);
  placesAutocomplete('.js-postal-code', zips, extractCity);
});
