LemonFrog.OptionAreaComponent = (function () {
  const moduleRef = `[data-module=OptionAreaComponent]`;
  const inputRef = `${moduleRef} input`;

  function init() {
    $(document).on('change', inputRef, function () {
      $(moduleRef).each(function () {
        $(this).toggleClass('option-area--checked', $(this).find('input').prop('checked'));
      });
    });

    $(document).on('click', moduleRef, function () {
      const optionInput = $(this).find('input'),
        checked = !optionInput.prop('checked');
      optionInput.prop('checked', checked);
      optionInput.trigger('change').trigger('input');
    });
  }

  return {
    init: init
  }
}());

LemonFrog.OptionAreaComponent.init();
