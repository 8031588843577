import consumer from "../consumer";

export default consumer.subscriptions.create('FieldsChannel', {
  received: function(data) {
    let $target = $(data.target);
    let newToken = data.value;
    let newValue = $target.val() + newToken;

    $target.focus();
    $target.val(newValue);
    $target.parsley()?.validate();
  },
});
