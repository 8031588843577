import { getBreakpoint } from '/helpers/ui';
import { capitalize } from '/helpers/string';

LemonFrog.Dropdown = (function() {
  function init() {
    $(document).on('click', function(e) {
      const $target = $(e.target);

      if ($target.hasClass('dropdown-skip-listener') || $target.parents('.dropdown-skip-listener').length) {
        return
      }

      if ($target.hasClass('dropdown-toggle') || $target.parents('.dropdown-toggle').length) {
        e.preventDefault();
        const $dropdown = $target.parents('.dropdown');

        closeOpenedDropdowns($dropdown);
        toggleDropdown($dropdown);
      } else {
        closeOpenedDropdowns();
      }
    });

    $(document).on('click', '.dropdown-item:not(.dropdown-skip-listener)', function() {
      const $dropdown = $(this).closest('.dropdown');
      closeDropdown($dropdown);
    });
  }

  function closeOpenedDropdowns($except) {
    $('.dropdown.open').not($except).each(function() {
        closeDropdown($(this));
    });
  }

  function toggleDropdown($dropdown) {
    const $toggle = $dropdown.children('.dropdown-toggle');
    const isExpanded = $toggle.attr('aria-expanded') == 'true' ? true : false;

    $dropdown.toggleClass('open');
    place($dropdown);
    $toggle.attr('aria-expanded', !isExpanded);
  }

  function closeDropdown($dropdown) {
    if ($dropdown.hasClass('open')) {
        $dropdown.removeClass('open');
        $dropdown.find('.dropdown-toggle').attr('aria-expanded', 'false');
    }
  }

  function place($dropdown) {
    const cords = getOverflowParentCords($dropdown);

    placeVertically($dropdown, cords);
    placeHorizontally($dropdown, cords);
  }

  function placeVertically($dropdown, overflowingParentCords) {
    const $menu = $dropdown.find('.dropdown-menu');
    $menu.removeClass('dropup');
    $menu.css({ height: initialValue($menu, 'height') });
    $menu.css({ bottom: initialValue($menu, 'bottom') });
    const menuHeight = $menu.outerHeight();

    // return if menu is visible with initial styling
    if ($menu.offset().top > overflowingParentCords.top &&
        $menu.offset().top + menuHeight < overflowingParentCords.bottom) return;

    const topSpaceForMenu = $dropdown.offset().top - overflowingParentCords.top;
    const bottomSpaceForMenu = overflowingParentCords.bottom - $menu.offset().top;

    if (topSpaceForMenu > bottomSpaceForMenu) {
      $menu.css({ bottom: $dropdown.outerHeight() })
      if (menuHeight > topSpaceForMenu) {
        $menu.css({ height: topSpaceForMenu - 32 });
      }
    } else {
      $menu.css({ bottom: "auto" });
      if (menuHeight > bottomSpaceForMenu) {
        $menu.css({ height: bottomSpaceForMenu - 16 });
      }
    }
  }

  function placeHorizontally($dropdown, overflowingParentCords) {
    const $menu = $dropdown.find('.dropdown-menu');
    $menu.width(initialValue($menu, 'width'));
    $menu.css({
      left: initialValue($menu, 'left'),
      right: initialValue($menu, 'right')
    });
    const menuWidth = $menu.outerWidth();

    // return if menu is visible with initial styling
    if ($menu.offset().left > overflowingParentCords.left &&
        $menu.offset().left + menuWidth < overflowingParentCords.right) return;

    const leftSpaceForMenu = $dropdown.offset().left + $dropdown.outerWidth() - overflowingParentCords.left;
    const rightSpaceForMenu = overflowingParentCords.right - $dropdown.offset().left;

    if (leftSpaceForMenu > rightSpaceForMenu) {
      $menu.css({ right: 0, left: "auto" });
      if (menuWidth > leftSpaceForMenu) {
        $menu.width(leftSpaceForMenu - 16);
      }
    } else {
      $menu.css({ right: "auto", left: 0 });
      if (menuWidth > rightSpaceForMenu) {
        $menu.width(rightSpaceForMenu - 16);
      }
    }
  }

  function getOverflowParentCords($dropdown) {
    let overflowingParentY = $dropdown.parents().filter((idx, el) => {
      return $(el).css('overflow-y') != 'visible';
    }).first();
    let overflowingParentX = $dropdown.parents().filter((idx, el) => {
      return $(el).css('overflow-x') != 'visible';
    }).first();

    const isTopOfOverflowingParentVisible = overflowingParentY.length && (overflowingParentY.offset().top > window.scrollY);
    const isBottompOfOverflowingParentVisible = overflowingParentY.length &&
          (overflowingParentY.offset().top + overflowingParentY.innerHeight() < window.scrollY + window.innerHeight)
    const isLeftOfOverflowingParentVisible = overflowingParentX.length && (overflowingParentX.offset().left > window.scrollX)
    const isRightOfOverflowingParentVisible = overflowingParentX.length &&
          (overflowingParentX.offset().left + overflowingParentX.innerWidth() < window.scrollX + window.innerWidth)

    const top = isTopOfOverflowingParentVisible ? overflowingParentY.offset().top : window.scrollY;
    const bottom = isBottompOfOverflowingParentVisible ?
          overflowingParentY.offset().top + overflowingParentY.innerHeight() :
          window.scrollY + window.innerHeight;
    const left = isLeftOfOverflowingParentVisible ? overflowingParentX.offset().left : window.scrollX;
    const right = isRightOfOverflowingParentVisible ?
          overflowingParentX.offset().top + overflowingParentX.innerWidth() :
          window.scrollX + window.innerWidth;

    return { left, top, right, bottom };
  }

  function initialValue($el, prop) {
    const key = `initial${capitalize(prop)}`;
    return $el.data(key) || $el.data(key, $el.css(prop)).data(key);
  }

  return {
    init: init
  }
}());

LemonFrog.Dropdown.init();
