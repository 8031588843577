LemonFrog.AutocompleteFieldComponent = (function () {
  const moduleName = 'AutocompleteFieldComponent';
  const autocompleteSource = {
    'place': '/api/v1/autocomplete'
  }

  function init() {
    $(`[data-module=${moduleName}]`).each(function () {
      const $input = $(this),
        container = $input.parents('.new-styling.input-field__box'),
        loadSpinner = container.find('.new-styling.autocomplete-component__loading-icon'),
        clearIcon = container.find('.new-styling.autocomplete-component__clear-icon');
      $input.autocomplete({
        source: autocompleteSource[$input.data('autocomplete-source')],
        select: sendForm,
        position: {
          my: 'left top',
          at: 'left bottom',
          collision: 'none flip',
          of: container
        },
        appendTo: container,
        search: function (event, ui) {
          loadSpinner.addClass('visible');
        },
        response: function (event, ui) {
          loadSpinner.removeClass('visible');
          if (ui.content && !ui.content.length) {
            const noResult = { value: '', label: $input.data('not-found-message'), isCaption: true };
            ui.content.push(noResult);
          }
        },
      });

      $input.autocomplete('widget').menu({
        classes: {
          'ui-menu': 'new-styling pa-8 rounded-4 elevation-50',
          'ui-menu-item-wrapper': 'new-styling text-base clickable rounded-4 flex autocomplete-component__list-item fill-primary'
        }
      })

      $input.autocomplete('instance')._renderItem = function( ul, item ) {
        const keywords = this.term.split(' ').join('|'),
          label = item.label.replace(
            new RegExp('(' + '^' + RegExp.escape(keywords) + ')', 'gi'),
            '<span class="new-styling color-primary contents ">$1</span>'
          ),
          prepend = container.find('.new-styling.input-field__extra-content.inner.prepend').html();

        return $("<li>")
          .append("<div>" + (prepend && !item.isCaption ? prepend : '') + "<div class='new-styling text-overflow-ellipsis' title='" + item.label + "'>" + label + "</div>" + "</div>")
          .appendTo(ul);
      };

      $input.on('autocompleteopen', function (event) {
        const inputWidth = container.outerWidth();
        $(this).autocomplete('widget').css('width', inputWidth);
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
        }
      });

      $input.on('input', (event) => { clearIcon.toggleClass('visible', event.target.value.length > 0) });
      clearIcon.on('click', () => {
        $input.val('').focus();
        clearIcon.removeClass('visible');
      });
    });
  }

  function sendForm (event, ui) {
    $(this).val(ui.item.value);
    $(this).parents('form').submit();
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.AutocompleteFieldComponent);
