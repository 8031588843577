LemonFrog.CancellationFeedbackComponent = (function () {
  function init() {
    $(document).on('change', '[data-module=CancellationFeedbackComponent] input', function () {
      const feedbackForm = $(this).closest('form');
      feedbackForm.find('button').toggleClass('disabled', !feedbackForm.find('input:checked').length);
    });
  }
  return {
    init: init
  }
}());

LemonFrog.CancellationFeedbackComponent.init();
